import React from "react";
import Header from "../header/Header";
import Axios from "axios";
import moment from "moment";
import "../../components/attendanceReport/Report.css";
import "./ActionItems.css";
import * as XLSX from "xlsx";
import Select from "react-select";
const config = require("../../config/config");

class ActionItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportList: [],
      filteredReportList: [],
      actionGroupList: [],
      nameList: [],
      selectedNames: [],
      selectedDateRanges: [], // Store multiple selected date ranges
      groupedReportList: {},
    };

    this.getReport = this.getReport.bind(this);
    this.prepareTableRows = this.prepareTableRows.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
    this.getActionItemsGroups = this.getActionItemsGroups.bind(this);
  }

  componentDidMount() {
    this.getActionItemsGroups();
  }

  // Fetch action items groups from the API
  getActionItemsGroups() {
    Axios.get(config.API_BASE + "/user/getActionItemsGroups")
      .then((response) => {
        let res = response.data;
        if (res.status && res.data) {
          const actionGroupList = res.data;

          this.setState({ actionGroupList }, () => {
            const firstActionGroup = actionGroupList[0];
            this.setState(
              {
                selectedDateRanges: [
                  {
                    startDate: firstActionGroup.startDate,
                    endDate: firstActionGroup.endDate,
                  },
                ],
              },
              () => {
                this.getReport();
              }
            );
          });
        } else {
          console.error("Error fetching action group data:", res.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching action group data:", error);
      });
  }

  // Handle the change of the selected date range from the dropdown
  handleDateRangeChange(selectedOptions) {
    this.setState({ selectedDateRanges: selectedOptions }, () => {
      this.getReport();
    });
  }

  // Handle the change of the selected names from the dropdown
  handleNameChange(selectedOptions) {
    const selectedNames = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    this.setState({ selectedNames }, () => {
      this.getReport();
    });
  }

  // Fetch report data based on selected date range and selected names
  getReport() {
    const { selectedDateRanges, selectedNames } = this.state;

    // Convert selected date ranges into the desired format: "YYYY-MM-DD|YYYY-MM-DD"
    const dateRanges = selectedDateRanges.map(
      (range) =>
        `${moment(range.startDate).format("YYYY-MM-DD")}|${moment(
          range.endDate
        ).format("YYYY-MM-DD")}`
    );

    // Prepare the request data
    let axiosData = {
      dateBetween: dateRanges, // Send the date ranges as an array
    };

    // If names are selected, include them in the request data
    if (selectedNames.length > 0) {
      axiosData.names = selectedNames;
    }

    // Use POST request to send the data in the body
    Axios.post(config.API_BASE + "/user/getActionItemsStatusReport", axiosData)
      .then((response) => {
        let res = response.data;
        if (res.status && res.data) {
          const reportList = res.data;

          // Group the report list by name
          const groupedData = reportList.reduce((acc, item) => {
            const { name, actionItems, status, period } = item;

            if (!acc[name]) {
              acc[name] = [];
            }

            // If status is null, assign "Not Available"
            const itemStatus = status === null ? "Not Available" : status;
            acc[name].push({ actionItems, status: itemStatus, period });
            return acc;
          }, {});

          // List of unique names for display or further processing
          const nameList = Object.keys(groupedData);

          this.setState(
            {
              reportList,
              groupedReportList: groupedData,
              nameList,
            },
            () => {
              this.filterReportBySelectedNames(); // Filter by selected names if necessary
            }
          );
        } else {
          console.error("Error fetching report data:", res.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching report data:", error);
      });
  }

  // Filter the report data by selected names
  filterReportBySelectedNames() {
    const { selectedNames, groupedReportList } = this.state;

    if (selectedNames.length === 0) {
      this.setState({ filteredReportList: groupedReportList });
      return;
    }

    const filteredData = Object.keys(groupedReportList)
      .filter((name) => selectedNames.includes(name))
      .reduce((acc, name) => {
        acc[name] = groupedReportList[name];
        return acc;
      }, {});

    this.setState({ filteredReportList: filteredData });
  }

  // Prepare table rows for rendering
  prepareTableRows() {
    const { filteredReportList } = this.state;

    if (!filteredReportList) return null;

    let rows = [];
    const sortedNames = Object.keys(filteredReportList).sort();

    sortedNames.forEach((name) => {
      const actionItemsList = filteredReportList[name];
      const actionItemCount = actionItemsList.length;

      actionItemsList.forEach((item, index) => {
        let emailFormat = "";

        try {
          if (name.includes("@")) {
            emailFormat = name.substring(0, name.lastIndexOf("@"));
            emailFormat = emailFormat.split(".").join(" ");
          }
        } catch (err) {
          emailFormat = name;
        }

        const actionItems = item.actionItems || "";
        const status = item.status || "Not Available";
        let period = item.period || ""; // Fallback to empty string if period is missing

        // Format the period if it exists
        if (period) {
          const [startDate, endDate] = period.split(" - ");

          const formattedStartDate = moment(startDate).format("D MMM YY");
          const formattedEndDate = moment(endDate).format("D MMM YY");

          // Combine the formatted dates into the final period string
          period = `${formattedStartDate} - ${formattedEndDate}`;
        }

        rows.push(
          <tr key={`${name}-${index}`}>
            {index === 0 ? (
              <td rowSpan={actionItemCount} className="name">
                {emailFormat || name}
              </td>
            ) : null}

            <td>{actionItems}</td>
            <td>{status}</td>
            <td>{period}</td>
          </tr>
        );
      });
    });

    return rows;
  }

  // Export report data to Excel
  exportToExcel() {
    const { filteredReportList } = this.state;

    // Ensure that filteredReportList contains the filtered data based on selected filters
    let exportData = [];

    // Convert the filtered report list into an array format suitable for exporting
    Object.keys(filteredReportList).forEach((name) => {
      const actionItemsList = filteredReportList[name];

      actionItemsList.forEach((item) => {
        const { actionItems, status, period } = item;

        // Prepare a single row of data for export
        exportData.push({
          Name: name,
          ActionItem: actionItems,
          Status: status,
          Period: period,
        });
      });
    });

    // If there is no data to export, return early
    if (exportData.length === 0) {
      console.log("No data available for export.");
      return;
    }

    // Convert the filtered data to an Excel sheet
    const ws = XLSX.utils.json_to_sheet(exportData); // Convert filtered data to Excel sheet
    const wb = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(wb, ws, "Filtered Report"); // Append the sheet to the workbook

    // Trigger the download of the Excel file with the name "Filtered_Report.xlsx"
    XLSX.writeFile(wb, "Filtered_Report.xlsx");
  }

  render() {
    const tableRows = this.prepareTableRows();
    const { nameList, selectedNames, actionGroupList, selectedDateRanges } =
      this.state;

    // Format options for react-select
    const dateRangeOptions = actionGroupList
      .filter(
        (range) =>
          !selectedDateRanges.some(
            (selected) =>
              selected.startDate === range.startDate &&
              selected.endDate === range.endDate
          )
      )
      .map((range) => ({
        label: `${new Date(range.startDate).toLocaleDateString()} - ${new Date(
          range.endDate
        ).toLocaleDateString()}`,
        value: `${range.startDate}|${range.endDate}`,
        startDate: range.startDate,
        endDate: range.endDate,
      }));

    const nameOptions = nameList
      .map((name) => ({
        label: name,
        value: name,
      }))
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });

    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="reportSectionMain card">
            <div className="row filterSection">
              <div
                className="col-xs-12 displayFlex"
                style={{ display: "flex", alignItems: "center" }}
              >
                {/* Date Range Selector */}
                <div className="dropdown-container">
                  <Select
                    isMulti
                    value={selectedDateRanges}
                    options={dateRangeOptions}
                    onChange={this.handleDateRangeChange}
                    getOptionLabel={(option) => {
                      const startDate = moment(option.startDate).format(
                        "Do MMM YYYY"
                      );
                      const endDate = moment(option.endDate).format(
                        "Do MMM YYYY"
                      );
                      return `${startDate} - ${endDate}`;
                    }}
                    closeMenuOnSelect={false}
                    placeholder="Select Date Ranges"
                  />
                </div>

                {/* Name Selector */}
                <div
                  className="dropdown-container"
                  style={{ marginLeft: "10px" }}
                >
                  <Select
                    isMulti
                    value={selectedNames.map((name) => ({
                      label: name,
                      value: name,
                    }))}
                    options={nameOptions.sort()}
                    onChange={this.handleNameChange}
                    closeMenuOnSelect={false}
                    placeholder="Select Names"
                  />
                </div>

                {/* Export Button */}
                <button
                  className="filterBtn btn"
                  onClick={this.exportToExcel}
                  style={{
                    marginLeft: "auto",
                    marginTop: 0,
                    marginRight: "5px",
                  }}
                >
                  Export to Excel
                </button>
              </div>
            </div>

            {/* Report Table */}
            <div className="row tableSection">
              <div className="col-xs-12">
                <div className="table-responsive">
                  <table className="table table-striped table-bordered table-hover table-condensed">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Action Item</th>
                        <th>Statuses</th>
                        <th>Period</th>
                      </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ActionItems;
