import React from "react";
import ReactDOM from "react-dom";  // Use ReactDOM here instead of createRoot
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Instead of createRoot, use ReactDOM.render
const container = document.getElementById("root");
ReactDOM.render(<App />, container);  // This renders the App component to the root element

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
