import React, { useState, useEffect } from "react";
import "../dashboard/Dashboard.css";
import moment from "moment";
import { Button, Modal, Form, Row, Col, ListGroup } from "react-bootstrap";
import Axios from "axios";
import Header from "../header/Header";
import { FaEdit, FaTrash, FaSave } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { verifyToken } from "../../helpers/signJwtToken";
import "react-toastify/dist/ReactToastify.css";
import { random } from "underscore";
var config = require("../../config/config");
var queryString = require("query-string");

let momentUiDateFormat = "DD MMMM YYYY";
let startYear = 2014;
let todayDate = new Date();
let thisYear = todayDate.getFullYear() + 2;
let API_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
let API_DATE_FORMAT = "YYYY-MM-DD";
let DATE_START_FORMAT = "YYYY-MM-DD 00:00:00";
let DATE_END_FORMAT = "YYYY-MM-DD 23:59:59";
let blockTime = "11:00am";
let lastTime = "11:59pm";
let yesterday = "";
let previousTwoDays = [];
const ACTUAL_IMPLEMENTED_DATE = new Date("April 01, 2023 23:15:30"); //From this onwords Actual Task will be mentetory.

const getCurrentWeekDates = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // Get the current day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

  // Calculate the start of the week (Monday)
  const startOfCurrentWeek = new Date(today);
  startOfCurrentWeek.setDate(
    today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 2)
  ); // Adjust to Monday

  // Calculate the end of the week (Friday)
  const endOfCurrentWeek = new Date(startOfCurrentWeek);
  endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 4); // Friday

  // Format dates as YYYY-MM-DD
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  return {
    startDate: formatDate(startOfCurrentWeek),
    endDate: formatDate(endOfCurrentWeek),
  };
};

const getPreviousWeekDates = () => {
  const today = new Date();
  const firstDayOfCurrentWeek = today.getDate() - today.getDay(); // Sunday
  const startOfPreviousWeek = new Date(
    today.setDate(firstDayOfCurrentWeek - 5)
  );
  const endOfPreviousWeek = new Date(
    today.setDate(startOfPreviousWeek.getDate() + 4)
  );

  // Format dates as YYYY-MM-DD
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  return {
    startDate: formatDate(startOfPreviousWeek),
    endDate: formatDate(endOfPreviousWeek),
  };
};
class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      month: ("0" + (moment().month() + 1)).slice(-2),
      year: moment().year(),
      calendarData: [],
      cellDetails: {},
      selectedCell: moment().format("YYYY-MM-DD"), // Assuming API_DATE_FORMAT is "YYYY-MM-DD"
      showStatusModal: false,
      status: [],
      userDetails: null,
      requestAdmin: false,
      allCalenderData: [],
      showCodeOfConductModal: false,
      showUpdatePreviousWeekModal: false, // State to control UpdatePreviousWeekModal visibility
      actionItems: [], // State to hold action items
      showCurrentWeekTasksModal: false,
      currentWeekTasks: [],
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCellClick = this.handleCellClick.bind(this);
    this.changeStatusModal = this.changeStatusModal.bind(this);
    this.getAllMarkStatus = this.getAllMarkStatus.bind(this);
    this.getCalenderData = this.getCalenderData.bind(this);
    this.changeCodeOfConductModal = this.changeCodeOfConductModal.bind(this);
    this.editStatus = this.editStatus.bind(this);
    this.fetchPreviousWeekActions = this.fetchPreviousWeekActions.bind(this);
  }

  async componentDidMount() {
    const userDetails = await this.getUserDetails();
    this.setState(() => ({
      userDetails: { ...userDetails },
    }));
    this.getAllMarkStatus();
    let weekday = moment().weekday();

    if (weekday !== 0 && weekday !== 6) {
      this.getUserTodayStatus(userDetails);
    }

    this.getCalenderData(userDetails);
  }

  async getUserDetails() {
    let userDetails = localStorage.getItem("token");
    if (userDetails) {
      const verifiedToken = await verifyToken(userDetails);
      return verifiedToken?.payload?.sub;
    }
    return null;
  }

  getCalenderData(userDetails) {
    let that = this;
    let tempStartDate = this.state.year + "-" + this.state.month + "-01";
    let endOfMonth = moment(tempStartDate)
      .endOf("month")
      .format(config.API_DATE_FORMAT);
    let startOfMonth = moment(tempStartDate)
      .startOf("month")
      .format(config.API_DATE_FORMAT);

    Axios.post(config.API_BASE + "/user/getUserAttendanceCalender", {
      userId: userDetails ? userDetails.id : that.state.userDetails.id,
      dateBetween: startOfMonth + "|" + endOfMonth,
    })
      .then(function (response) {
        let res = response.data;
        if (res.status) {
          let calenderData = typeof res.data !== "undefined" ? res.data : [];
          let formatCalenderData = {};
          let cellDetails = {};

          calenderData.map(function (value, index) {
            formatCalenderData[
              moment(value.calenderDate).format(config.API_DATE_FORMAT)
            ] = value;
            if (
              moment(value.calenderDate).format(config.API_DATE_FORMAT) ==
              that.state.selectedCell
            ) {
              cellDetails = value;
            }
          });
          that.setState({
            calendarData: formatCalenderData,
            cellDetails: cellDetails,
            allCalenderData: calenderData,
          });
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }

  getUserTodayStatus(userDetails) {
    let that = this;
    Axios.post(config.API_BASE + "/user/getUserAttendance", {
      userId: userDetails.id,
      attendanceDate: moment().format(config.API_DATE_FORMAT),
    })
      .then(function (response) {
        let res = response.data;
        if (res.status) {
          //After 11 don't show modal
          if (
            typeof res.data !== "undefined" &&
            res.data.length === 0 &&
            moment().isBefore(moment(blockTime, "h:mma"))
          ) {
            that.changeCodeOfConductModal(true);
          }

          if (res.data.length > 0) {
            // Check if today is Monday and fetch previous week's actions
            that.fetchPreviousWeekActions(that.state.userDetails);
            // After fetching user details
            that.fetchCurrentWeekTasks(that.state.userDetails);
          }
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }

  async fetchPreviousWeekActions(userDetails) {
    const { startDate, endDate } = getPreviousWeekDates();
    try {
      const response = await fetch(
        `${config.API_BASE}/user/fetchUserActions?userId=${userDetails.id}&startDate=${startDate}&endDate=${endDate}`
      );
      const data = await response.json();

      if (data.status && data.data) {
        // Check if action items have empty status
        const hasEmptyStatus = data.data.some((item) => !item.status);
        if (hasEmptyStatus) {
          this.setState({
            showUpdatePreviousWeekModal: true,
            actionItems: data.data,
          });
        }
      }
    } catch (error) {
      console.log("API Error:", error);
    }
  }

  async fetchCurrentWeekTasks(userDetails) {
    const { startDate, endDate } = getCurrentWeekDates();
    try {
      const response = await fetch(
        `${config.API_BASE}/user/fetchUserActions?userId=${userDetails.id}&startDate=${startDate}&endDate=${endDate}`
      );
      const data = await response.json();

      if (data.status && data.data.length == 0) {
        this.setState({
          currentWeekTasks: data.data, // Assuming data.data is an array of current week tasks
          showCurrentWeekTasksModal: true, // Show modal if there are tasks
        });
      }
    } catch (error) {
      console.log("API Error:", error);
    }
  }

  getAllMarkStatus() {
    let that = this;
    Axios.get(config.API_BASE + "/status/getStatus")
      .then(function (response) {
        let res = response.data;
        that.setState({ status: res.data });
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }

  handleDateChange(year, month) {
    this.setState(
      { month: month, year: year, selectedCell: 0 },
      this.getCalenderData
    );
  }

  handleCellClick(data) {
    this.setState({ selectedCell: data.cellDate, cellDetails: data.cellData });
  }

  changeCodeOfConductModal(state) {
    this.setState({ showCodeOfConductModal: state });
  }

  changeStatusModal(state, requestAdmin = false) {
    if (state) {
      // Check if today is Monday and fetch previous week's actions
      this.fetchPreviousWeekActions(this.state.userDetails);
      // After fetching user details
      this.fetchCurrentWeekTasks(this.state.userDetails);
    }
    this.setState({ showStatusModal: state });
  }

  editStatus(cellDetails) {
    this.setState({ cellDetails }, () => this.changeStatusModal(true, false));
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <Calendar
                ref="cal"
                month={this.state.month}
                year={this.state.year}
                selectedVenues={this.state.selectedVenues}
                calendarData={this.state.calendarData}
                selectedCell={this.state.selectedCell}
                handleDateChange={this.handleDateChange}
                handleCellClick={this.handleCellClick}
              />
            </div>
            <div className="col-md-4">
              <CardDetails
                cellDetails={this.state.cellDetails}
                changeCodeOfConductModal={this.changeCodeOfConductModal}
                changeStatusModal={this.changeStatusModal}
                editStatus={this.editStatus}
                allCalenderData={this.state.allCalenderData}
              />
            </div>
          </div>
          <MarkStatusModal
            {...this.state}
            openModal={this.state.showStatusModal}
            changeStatusModal={this.changeStatusModal}
            getCalenderData={this.getCalenderData}
          />
          <UpdatePreviousWeekModal
            show={this.state.showUpdatePreviousWeekModal} // Control visibility from state
            handleClose={() =>
              this.setState({ showUpdatePreviousWeekModal: false })
            } // Handle modal close
            userId={this.state.userDetails?.id} // Pass userId from userDetails
            actionItems={this.state.actionItems} // Pass actionItems to the modal
          />
          <CurrentWeekTasks
            show={this.state.showCurrentWeekTasksModal} // Control visibility from state
            handleClose={() =>
              this.setState({ showCurrentWeekTasksModal: false })
            } // Handle modal close
            userId={this.state.userDetails?.id} // Pass userId from userDetails
            actionItems={this.state.currentWeekTasks} // Pass current week action items to the modal
          />
          <CodeOfConductModal
            {...this.state}
            showCodeOfConductModal={this.state.showCodeOfConductModal}
            changeStatusModal={this.changeStatusModal}
            changeCodeOfConductModal={this.changeCodeOfConductModal}
          />
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            draggable
            pauseOnFocusLoss
            theme="colored"
          />
        </div>
      </>
    );
  }
}

class CardDetails extends React.Component {
  yesterDayCheck(allCalenderData) {
    const datesArray = allCalenderData;
    const today = new Date();
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let finalDates = [];
    let finalData = [];
    datesArray.forEach((calenderData) => {
      const today = new Date(calenderData.calenderDate);
      const weekdayName = weekdays[today.getDay()];

      if (
        weekdayName === "Sunday" ||
        weekdayName === "Saturday" ||
        (calenderData.holidayName != "" && calenderData.holidayName != null) ||
        calenderData.status == 5 ||
        calenderData.status == 4
      ) {
        return false;
      } else {
        finalDates.push(calenderData.calenderDate);
        finalData.push(calenderData);
      }
    });
    const formatedDate = moment(today).format("YYYY-MM-DD").toString();
    const indexOfDate = finalDates.findIndex((date) => date === formatedDate);
    const previousDay = indexOfDate - 1;
    const secondPreviousDay = indexOfDate - 2;

    previousTwoDays = [finalDates[previousDay], finalDates[secondPreviousDay]];

    previousTwoDays.forEach((dayValue) => {
      if (typeof dayValue === "undefined") {
        const currentDate = moment(todayDate).format("DD");
        if (
          currentDate === "01" ||
          currentDate === "02" ||
          currentDate === "03"
        ) {
          let final = [];
          finalData.forEach((day) => {
            if (day.taskForDay !== "" && day.taskForDay !== null) {
              final.push(day);
            }
          });

          if (final.length > 10) {
            return (previousTwoDays = finalDates.slice(-2));
          }
        }
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    this.yesterDayCheck(nextProps.allCalenderData);
  }
  render() {
    let todayDate = moment().format(API_DATE_FORMAT);
    let cellDate = moment(this.props.cellDetails.calenderDate).format(
      API_DATE_FORMAT
    );

    let eligible = false;
    if (
      moment().isBefore(moment(blockTime, "h:mma")) &&
      todayDate == cellDate &&
      (typeof this.props.cellDetails.status === "undefined" ||
        this.props.cellDetails.status == null)
    ) {
      eligible = true;
    }
    return (
      <div className="cardDetails">
        {typeof this.props.cellDetails.holidayName !== "undefined" &&
        this.props.cellDetails.holidayName != null &&
        this.props.holidayName != "" ? (
          <div className="holidayName">
            Leave: {this.props.cellDetails.holidayName}
          </div>
        ) : (
          ""
        )}
        {typeof this.props.cellDetails.status === "undefined" ||
        this.props.cellDetails.status == null ? (
          <div>No record found</div>
        ) : (
          <>
            <div className="row">
              <div className="col-sm-12 dateEditDiv">
                <div className="date">
                  {moment(this.props.cellDetails.attendanceDate).format(
                    momentUiDateFormat
                  )}{" "}
                </div>
                {this.props.cellDetails.attendanceId &&
                moment().isBefore(moment(lastTime, "h:mma")) &&
                todayDate === cellDate ? (
                  <div
                    className="editBtn"
                    onClick={this.props.editStatus.bind(
                      this,
                      this.props.cellDetails
                    )}
                  >
                    Edit
                  </div>
                ) : (
                  ""
                )}
                {this.props.cellDetails.attendanceId &&
                ((moment().isAfter(moment(lastTime, "h:mma")) &&
                  todayDate === cellDate) ||
                  previousTwoDays.includes(cellDate)) ? (
                  <div
                    className="editBtn"
                    onClick={this.props.editStatus.bind(
                      this,
                      this.props.cellDetails
                    )}
                  >
                    Enter Actuals
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row status">
              <div className="col-sm-4 bold">Status: </div>
              <div className="col-sm-8">
                {this.props.cellDetails.statusName}
              </div>
            </div>
            <div className="row task">
              <div className="col-sm-4 bold">Task: </div>
              <div className="col-sm-8 taskForDay">
                {this.props.cellDetails.taskForDay}
              </div>
            </div>

            <div className="row task">
              <div className="col-sm-4 bold">Actual task: </div>
              <div className="col-sm-8 taskForDay">
                {this.props.cellDetails.actualTask}
              </div>
            </div>
            {typeof this.props.cellDetails.additionalNote !== "undefined" &&
            this.props.cellDetails.additionalNote != null &&
            this.props.cellDetails.additionalNote != "" ? (
              <div className="row additionalNotes">
                <div className="col-sm-4 bold">Additional Notes: </div>
                <div className="col-sm-8 additionalNotesDay">
                  {this.props.cellDetails.additionalNote}
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        )}
        {typeof this.props.cellDetails.markStatus !== "undefined" &&
        this.props.cellDetails.markStatus == 0 &&
        todayDate == cellDate &&
        moment().isBefore(moment(blockTime, "h:mma")) ? (
          <div className="sendRequest">
            <button
              className="btn"
              onClick={this.props.changeCodeOfConductModal.bind(this, true)}
            >
              Mark Attendance
            </button>
          </div>
        ) : (
          ""
        )}
        {typeof this.props.cellDetails.markStatus !== "undefined" &&
        this.props.cellDetails.markStatus == 0 &&
        todayDate == cellDate &&
        moment().isBetween(
          moment(blockTime, "h:mma"),
          moment(lastTime, "h:mma")
        ) ? (
          <div className="sendRequest">
            <button
              className="btn"
              onClick={this.props.changeCodeOfConductModal.bind(
                this,
                true,
                true
              )}
            >
              Regularise Attendance
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarData: this.props.calendarData,
      month: this.props.month,
      year: this.props.year,
    };

    this.handleLeftArrowClick = this.handleLeftArrowClick.bind(this);
    this.handleRightArrowClick = this.handleRightArrowClick.bind(this);
    this.processArrowClick = this.processArrowClick.bind(this);
    this.resetToTodayDate = this.resetToTodayDate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      calendarData: nextProps.calendarData,
      month: nextProps.month,
      year: nextProps.year,
    });
  }

  componentDidMount() {}

  getCalendarMonthObj(year, month) {
    let monthObj = {};
    let startDate = moment([year, month]);
    let dayOfWeek = startDate.weekday();
    let numberOfDays = startDate.endOf("month").date();
    let date = 1;
    for (let i = 0; i <= 5; i++) {
      monthObj[i] = {};
      for (let j = dayOfWeek; j < 7; j++) {
        if (date == numberOfDays + 1) {
          break;
        }
        monthObj[i][j] = date++;
      }
      dayOfWeek = 0;
    }
    return monthObj;
  }

  handleLeftArrowClick() {
    let newDate = moment([this.state.year, this.state.month - 1]).subtract(
      1,
      "months"
    );
    this.processArrowClick(newDate);
  }

  handleRightArrowClick() {
    let newDate = moment([this.state.year, this.state.month - 1]).add(
      1,
      "months"
    );
    this.processArrowClick(newDate);
  }

  processArrowClick(newDate) {
    this.state.year = newDate.format("YYYY");
    this.state.month = newDate.format("MM");

    if (typeof this.props.handleDateChange !== "undefined") {
      this.props.handleDateChange(this.state.year, this.state.month);
    }
  }

  resetToTodayDate() {
    let month = ("0" + (moment().month() + 1)).slice(-2);
    let year = moment().year();

    let data = {};
    data.day = ("0" + moment().date()).slice(-2);

    this.props.handleDateChange(year, month);

    let calendarData = this.state.calendarData;
    let todayDate = moment().format(config.API_DATE_FORMAT);

    if (typeof this.props.handleCellClick !== "undefined") {
      let data = {
        cellDate: todayDate,
        cellData:
          typeof calendarData[todayDate] !== "undefined"
            ? calendarData[todayDate]
            : {},
      };
      this.props.handleCellClick(data);
    }
  }

  render() {
    let MonthObj = this.getCalendarMonthObj(
      this.props.year,
      this.props.month - 1
    );
    let tableRows = [];
    let cell = [];
    let calendarData = this.state.calendarData;

    let cellBg = [];

    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 7; j++) {
        let cellDate =
          this.props.year +
          "-" +
          this.props.month +
          "-" +
          ("0" + MonthObj[i][j]).slice(-2);
        let highLight = this.props.selectedCell == cellDate ? true : false;

        cellBg.push(
          <td
            key={j}
            className="fc-day fc-widget-content fc-sun fc-other-month fc-past"
            data-date={cellDate}
          ></td>
        );

        cell.push(
          <Cell
            {...this.props}
            highLight={highLight}
            reloadCalendar={this.props.reloadCalendar}
            key={j}
            month={this.props.month}
            year={this.props.year}
            day={MonthObj[i][j]}
            cellDate={cellDate}
            cellData={
              typeof calendarData[cellDate] !== "undefined"
                ? calendarData[cellDate]
                : {}
            }
            handleCellClick={this.props.handleCellClick}
          />
        );
      }
      tableRows.push(
        <div key={i} className="fc-row fc-week fc-widget-content fc-rigid">
          <div className="fc-bg">
            <table className="">
              <tbody>
                <tr>{cellBg}</tr>
              </tbody>
            </table>
          </div>
          <div className="fc-content-skeleton">
            <table>
              <thead>
                <tr>{cell}</tr>
              </thead>
            </table>
          </div>
        </div>
      );
      cell = [];
      cellBg = [];
    }

    let monthStartDate = this.props.year + "-" + this.props.month + "-01";

    return (
      <div className="card card-calendar">
        <div className="card-body ">
          <div id="fullCalendar" className="fc fc-unthemed fc-ltr">
            <div className="fc-toolbar fc-header-toolbar">
              <div className="fc-left">
                <h3>
                  {moment(monthStartDate).format("MMMM")} {this.state.year}
                </h3>
              </div>
              <div className="hidden-xs fc-right">
                <div className="fc-button-group">
                  <button
                    type="button"
                    className="fc-prev-button fc-button fc-state-default fc-corner-left"
                    aria-label="prev"
                    onClick={this.handleLeftArrowClick}
                  >
                    <span className="fc-icon fc-icon-left-single-arrow"></span>
                  </button>
                  <button
                    type="button"
                    className="fc-next-button fc-button fc-state-default"
                    aria-label="next"
                    onClick={this.handleRightArrowClick}
                  >
                    <span className="fc-icon fc-icon-right-single-arrow"></span>
                  </button>
                  <button
                    type="button"
                    className="fc-today-button fc-button fc-state-default fc-corner-right"
                    onClick={this.resetToTodayDate}
                  >
                    TODAY
                  </button>
                </div>
              </div>
              <div className="fc-center">
                <MonthFilter
                  month={this.state.month}
                  year={this.state.year}
                  handleDateChange={this.props.handleDateChange}
                />
              </div>
              <div className="fc-clear"></div>
            </div>
            <div className="fc-view-container">
              <div className="fc-view fc-month-view fc-basic-view">
                <table className="fc-main-view">
                  <thead className="fc-head">
                    <tr>
                      <td className="fc-head-container fc-widget-header">
                        <div className="fc-row fc-widget-header">
                          <table className="">
                            <thead>
                              <tr>
                                <th className="fc-day-header fc-widget-header fc-sun">
                                  <span>Sun</span>
                                </th>
                                <th className="fc-day-header fc-widget-header fc-mon">
                                  <span>Mon</span>
                                </th>
                                <th className="fc-day-header fc-widget-header fc-tue">
                                  <span>Tue</span>
                                </th>
                                <th className="fc-day-header fc-widget-header fc-wed">
                                  <span>Wed</span>
                                </th>
                                <th className="fc-day-header fc-widget-header fc-thu">
                                  <span>Thu</span>
                                </th>
                                <th className="fc-day-header fc-widget-header fc-fri">
                                  <span>Fri</span>
                                </th>
                                <th className="fc-day-header fc-widget-header fc-sat">
                                  <span>Sat</span>
                                </th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody className="fc-body">
                    <tr>
                      <td className="fc-widget-content">
                        <div className="fc-scroller fc-day-grid-container">
                          <div className="fc-day-grid fc-unselectable">
                            {tableRows}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Cell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      year: this.props.year,
      month: this.props.month,
    };

    this.toggleDetailsModal = this.toggleDetailsModal.bind(this);
    this.handleCellClick = this.handleCellClick.bind(this);
  }

  toggleDetailsModal(toggle) {
    this.setState({ detailsModal: toggle });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      year: nextProps.year,
      month: nextProps.month,
    });
  }

  handleCellClick(cellDate) {
    if (typeof this.props.day === "undefined") {
      return false;
    }

    if (typeof this.props.handleCellClick !== "undefined") {
      let data = {
        cellDate:
          this.state.year +
          "-" +
          this.state.month +
          "-" +
          ("0" + this.props.day).slice(-2),
        cellData: this.props.cellData,
      };
      this.props.handleCellClick(data);
    }
  }

  render() {
    let cellDate =
      this.state.year +
      "-" +
      this.state.month +
      "-" +
      ("0" + this.props.day).slice(-2);
    let className = this.props.highLight ? "selected-cell" : "";
    let cellData =
      typeof this.props.cellData !== "undefined" ? this.props.cellData : {};
    let momentDateWeek = moment(cellDate).format("dddd");
    let isActualTaskSubmitted =
      typeof this.props.cellData.actualTask !== "undefined" &&
      this.props.cellData.actualTask != null &&
      this.props.cellData.actualTask != "";
    let isWeekEnd =
      momentDateWeek === "Sunday" || momentDateWeek === "Saturday";
    let isHoliday =
      typeof cellData.holidayName !== "undefined" &&
      cellData.holidayName != null &&
      cellData.holidayName != "";
    let statusColor = cellData.statusColor;
    let statusShortName = cellData.shortName;

    if (moment().diff(cellDate) > 0) {
      statusColor =
        cellData.markStatus == 0
          ? "rgb(218, 104, 104)"
          : !isActualTaskSubmitted &&
            cellData.calenderDate >=
              moment(ACTUAL_IMPLEMENTED_DATE).format("YYYY-MM-DD") &&
            !isHoliday &&
            ![4, 5].includes(cellData.status)
          ? "#da9b68"
          : cellData.statusColor;
      statusShortName =
        cellData.markStatus == 0 && cellData.status != 6
          ? "A"
          : cellData.shortName;
    }

    return (
      <td
        height="30"
        className={"fc-day fc-widget-content fc-past"}
        style={!isWeekEnd ? { backgroundColor: statusColor } : {}}
        data-date={cellDate}
        onClick={this.handleCellClick.bind(this, cellDate)}
      >
        <div style={{ marginTop: 10, marginRight: 10 }}>
          <div className="">
            <span
              className={
                this.props.highLight
                  ? "fc-day-number todayDate"
                  : "fc-day-number"
              }
            >
              {this.props.day}
            </span>
          </div>
        </div>
        <div className="hidden-xs">
          <div className="cellDataShortName">
            {isWeekEnd || isHoliday ? "L" : statusShortName}
          </div>
        </div>
      </td>
    );
  }
}

class MonthFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      year: this.props.year,
      month: this.props.month,
    };

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      year: nextProps.year,
      month: nextProps.month,
    });
  }

  handleMonthChange(event) {
    this.setState({ month: event.target.value }, function () {
      this.handleDateChange();
    });
  }

  handleYearChange(event) {
    this.setState({ year: event.target.value }, function () {
      this.handleDateChange();
    });
  }

  handleDateChange() {
    this.props.handleDateChange(this.state.year, this.state.month);
  }

  render() {
    let temp = startYear;
    let option = [];
    while (temp <= thisYear) {
      temp++;
      option.push(
        <option key={temp} value={temp}>
          {temp}
        </option>
      );
    }

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="col-xs-6 no-padding dropdown1">
            <select
              className="form-control select-box dropdown-width pull-right"
              id="month"
              value={this.state.month}
              onChange={this.handleMonthChange.bind(this)}
            >
              <option value="01">Jan</option>
              <option value="02">Feb</option>
              <option value="03">Mar</option>
              <option value="04">Apr</option>
              <option value="05">May</option>
              <option value="06">Jun</option>
              <option value="07">Jul</option>
              <option value="08">Aug</option>
              <option value="09">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </select>
          </div>
          <div className="col-xs-6 no-padding dropdown1">
            <select
              className="form-control select-box center-block dropdown-width"
              id="year"
              value={this.state.year}
              onChange={this.handleYearChange.bind(this)}
            >
              {option}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

class MarkStatusModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      details: {
        userId: null,
        attendanceDate: moment(this.props.cellDetails.calenderDate).format(
          API_DATE_FORMAT
        ),
        taskForDay: "",
        status: 0,
        additionalNote: "",
        missedReason: "",
        actualTask: "",
      },
      btnDisable: false,
      leaveBalance: {
        plBalance: 0,
        chBalance: 0,
      },
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isValid = this.isValid.bind(this);
    this.submitDetails = this.submitDetails.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.submitDetailsAdmin = this.submitDetailsAdmin.bind(this);
    this.resetData = this.resetData.bind(this);
    this.getUserLeaves = this.getUserLeaves.bind(this);
    this.updateDetails = this.updateDetails.bind(this);
  }

  async getUserDetails() {
    let userDetails = localStorage.getItem("token");
    if (userDetails) {
      const verifiedToken = await verifyToken(userDetails);
      return verifiedToken?.payload?.sub;
    }
    return null;
  }

  componentWillReceiveProps(nextProps) {
    let propCellDetails = nextProps.cellDetails;
    let details = this.state.details;
    details.attendanceDate = moment(nextProps.cellDetails.calenderDate).format(
      API_DATE_FORMAT
    );
    details.status = propCellDetails.status;
    details.taskForDay = propCellDetails.taskForDay;
    details.actualTask = propCellDetails.actualTask
      ? propCellDetails.actualTask
      : propCellDetails.taskForDay;
    details.missedReason = propCellDetails.missedReason;
    details.additionalNote = propCellDetails.additionalNote;
    details.attendanceId =
      typeof propCellDetails.attendanceId !== "undefined"
        ? propCellDetails.attendanceId
        : null;
    details.userId = nextProps.userDetails.id;
    this.setState({
      show: nextProps.openModal,
      details: details,
    });
  }

  async componentDidMount() {
    const userDetails = await this.getUserDetails();
    this.setState((prevdetails) => ({
      details: { ...prevdetails.details, userId: userDetails.id },
    }));
  }

  getUserLeaves() {
    let that = this;
    Axios.get(
      config.API_BASE +
        "/leave/getUserLeaves?" +
        queryString.stringify({
          userId: this.props.userDetails.id,
        })
    )
      .then(function (response) {
        let res = response.data;
        if (
          res.status &&
          typeof res.data !== "undefined" &&
          res.data.length > 0
        ) {
          let leaveObject = res.data[0];

          that.setState({ leaveBalance: leaveObject });
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }

  isValid() {
    let isValid = true;

    let details = this.state.details;
    let mendatoryFields = ["userId", "attendanceDate", "taskForDay", "status"];
    if (this.props.requestAdmin) {
      mendatoryFields.push("missedReason");
    }
    if (this.state.details.attendanceId) {
      mendatoryFields.push("attendanceId");
    }
    mendatoryFields.map(function (value, index) {
      if (
        typeof details[value] === "undefined" ||
        details[value] === "" ||
        details[value] == null
      ) {
        isValid = false;
        //exit from loop
        return false;
      }
    });
    if (typeof details.status === "undefined" || details.status == 0) {
      return false;
    }

    return isValid;
  }

  onSubmit() {
    // if (this.props.requestAdmin) {
    //   this.submitDetailsAdmin();
    // } else {
    //   this.submitDetails();
    // }
    if (this.state.details.attendanceId) {
      this.updateDetails();
    } else {
      this.submitDetails();
    }
  }

  submitDetailsAdmin() {
    if (!this.isValid()) {
      toast.error("Mendatory fields required");
      return false;
    }

    this.setState({ btnDisable: true }, function () {
      let that = this;
      let axiosData = {
        userId: this.props.userDetails.id,
        date: moment(this.state.details.attendanceDate).format(API_DATE_FORMAT),
        attendanceStatus: this.state.details.status,
        status: 0,
        task: this.state.details.taskForDay,
        additionalNotes: this.state.details.additionalNote,
      };

      Axios.post(
        config.API_BASE + "/attendance/insertAttendanceRequest",
        axiosData
      )
        .then(function (response) {
          let res = response.data;
          if (res.status) {
            toast.success("Request successfully sent to admin");
            if (typeof that.props.getCalenderData !== "undefined") {
              that.props.getCalenderData(that.props.userDetails);
            }
            that.resetData();
            that.handleClose();
          } else {
            toast.error(res.msg);
          }
          that.setState({ btnDisable: false });
        })
        .catch(function (error) {
          that.setState({ btnDisable: false });
          console.log("error", error);
        });
    });
  }

  resetData() {
    let details = this.state.details;
    details.taskForDay = "";
    details.status = 0;
    details.additionalNote = "";

    this.setState({ details: details });
  }

  updateDetails() {
    let todayDate = moment().format(API_DATE_FORMAT);
    let cellDate = moment(this.props.cellDetails.calenderDate).format(
      API_DATE_FORMAT
    );
    if (!this.isValid()) {
      toast.error("Mendatory fields required");
      return false;
    }

    this.setState({ btnDisable: true }, function () {
      let that = this;
      let axiosData = {
        attendanceId: this.state.details.attendanceId,
        userId: this.props.userDetails.id,
        date: moment(this.state.details.attendanceDate).format(API_DATE_FORMAT),
        attendanceStatus: this.state.details.status,
        status: 0,
        task: this.state.details.taskForDay,
        actualTask: this.state.details.actualTask,
        additionalNotes: this.state.details.additionalNote,
      };
      if (
        moment().isBefore(moment(lastTime, "h:mma")) &&
        cellDate == todayDate
      ) {
        axiosData.actualTask = "";
      }
      Axios.post(
        config.API_BASE + "/attendance/updateAttendaceDetails",
        axiosData
      )
        .then(function (response) {
          let res = response.data;
          if (res.status) {
            toast.success("Update Successfully");
            if (typeof that.props.getCalenderData !== "undefined") {
              that.props.getCalenderData(that.props.userDetails);
            }
            that.resetData();
            that.handleClose();
          } else {
            toast.error(res.msg);
          }
          that.setState({ btnDisable: false });
        })
        .catch(function (error) {
          that.setState({ btnDisable: false });
        });
    });
  }

  submitDetails() {
    if (!this.isValid()) {
      toast.error("Mendatory fields required");
      return false;
    }
    switch (this.state.details.status) {
      case "5":
        if (this.state.leaveBalance.plBalance <= 0) {
          toast.error("You don't have enough Paid Leaves");
          return false;
        }
        break;
      case "7":
        if (this.state.leaveBalance.chBalance <= 0) {
          toast.error("You don't have enough Optional Leaves");
          return false;
        }
        break;
    }
    let that = this;
    this.setState({ btnDisable: true }, function () {
      Axios.post(config.API_BASE + "/user/insertUserStatus", this.state.details)
        .then(function (response) {
          let res = response.data;
          if (res.status) {
            toast.success("Status successfully updated");
            if (typeof that.props.getCalenderData !== "undefined") {
              that.props.getCalenderData(that.props.userDetails);
            }
            that.resetData();
            that.handleClose();
            //Open CEO Dashboard
            window.open("https://app.ceoculturedashboard.com/login", "_blank");
          } else if (typeof res.msg !== "undefined") {
            toast.error(res.msg);
          } else {
            toast.error("Something went wrong");
          }
          that.setState({ btnDisable: false });
        })
        .catch(function (error) {
          console.log("error", error);
          toast.error("Something went wrong");
          that.setState({ btnDisable: false });
        });
    });
  }

  handleClose() {
    this.setState({ show: false }, function () {
      this.props.changeStatusModal(false);
    });
  }

  handleChange(state, evt) {
    let details = this.state.details;
    details[state] = evt.target.value;

    if (state == "status" && (evt.target.value == 5 || evt.target.value == 7)) {
      this.getUserLeaves();
    }
    this.setState({ details: details });
  }

  render() {
    let todayDate = moment().format(API_DATE_FORMAT);
    let cellDate = moment(this.props.cellDetails.calenderDate).format(
      API_DATE_FORMAT
    );
    let details = this.state.details;
    let that = this;
    let isOptionalHoliday =
      typeof this.props.cellDetails.optionalHolidayName !== "undefined" &&
      this.props.cellDetails.optionalHolidayName != null &&
      this.props.cellDetails.optionalHolidayName != "";
    return (
      <>
        <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleClose}
          className="markStatusModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Mark Your Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group as={Row} controlId="date">
                <Form.Label column sm="2">
                  Date
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={moment(details.attendanceDate).format(
                      momentUiDateFormat
                    )}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="statusmark">
                <Form.Label column sm="2" className="statusLabel">
                  Status <span className="compulsory">*</span>
                </Form.Label>
                <Col sm="10" className="statusNameCol">
                  <Form.Control
                    as="select"
                    disabled={
                      (moment().isAfter(moment(lastTime, "h:mma")) &&
                        todayDate === cellDate) ||
                      previousTwoDays.includes(cellDate)
                    }
                    value={details.status}
                    onChange={this.handleChange.bind(this, "status")}
                  >
                    <option value={0}>Select Option</option>
                    {this.props.status.map(function (value, index) {
                      if (
                        (!isOptionalHoliday &&
                          value.id == config.OPTIONAL_HOLIDAY) ||
                        value.id == config.WORK_WITHOUT_PAY
                      ) {
                        return;
                      }
                      return (
                        <option key={value.id} value={value.id}>
                          {value.statusName}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              {details.status == 5 || details.status == 7 ? (
                <Form.Group as={Row} controlId="displayLeaves">
                  <Form.Label column sm="2">
                    Leave Balance
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="text"
                      readOnly
                      value={this.state.leaveBalance.plBalance}
                    />
                  </Col>
                </Form.Group>
              ) : (
                ""
              )}
              <Form.Group as={Row} controlId="taskfortheday">
                <Form.Label column sm="2">
                  Task For The Day <span className="compulsory">*</span>
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="textarea"
                    rows="3"
                    // disabled={
                    //   !isActualTaskFilled ||
                    //   (moment().isAfter(moment(lastTime, "h:mma")) &&
                    //     todayDate === cellDate) ||
                    //   yesterday === cellDate
                    // }
                    disabled={
                      (moment().isAfter(moment(lastTime, "h:mma")) &&
                        todayDate === cellDate) ||
                      previousTwoDays.includes(cellDate)
                    }
                    value={details.taskForDay}
                    onChange={this.handleChange.bind(this, "taskForDay")}
                  />
                  {/* <h3 className="text-right text-warning">
                    {!isActualTaskFilled && todayDate === cellDate
                      ? "Please fill the last two days actual task to enable this field"
                      : ""}
                  </h3> */}
                </Col>
              </Form.Group>

              {(moment().isAfter(moment(lastTime, "h:mma")) &&
                todayDate === cellDate) ||
              previousTwoDays.includes(cellDate) ? (
                <Form.Group as={Row} controlId="actualTask">
                  <Form.Label column sm="2">
                    Actual Task For The Day{" "}
                    <span className="compulsory">*</span>
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      as="textarea"
                      rows="3"
                      value={details.actualTask}
                      onChange={this.handleChange.bind(this, "actualTask")}
                    />
                  </Col>
                </Form.Group>
              ) : (
                ""
              )}

              {this.props.requestAdmin &&
              moment().isAfter(moment(blockTime, "h:mma")) ? (
                <Form.Group as={Row} controlId="missedReason">
                  <Form.Label column sm="2">
                    Why I missed marking my attendance?{" "}
                    <span className="compulsory">*</span>
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      as="textarea"
                      rows="3"
                      value={details.missedReason}
                      onChange={this.handleChange.bind(this, "missedReason")}
                    />
                  </Col>
                </Form.Group>
              ) : (
                ""
              )}
              <Form.Group as={Row} controlId="additionalNotes">
                <Form.Label column sm="2">
                  Additional Notes
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="textarea"
                    rows="3"
                    value={details.additionalNote}
                    onChange={this.handleChange.bind(this, "additionalNote")}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={this.onSubmit}
              disabled={this.state.btnDisable}
            >
              {details.attendanceId ? "Update Details" : "Submit"}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

class CodeOfConductModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      selectedContent: "",
      userDetails: null,
      buttonDisabled: false,
      remainingSeconds: 15,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        modalIsOpen: nextProps.showCodeOfConductModal,
        userDetails: nextProps.userDetails,
      },
      () => {
        if (nextProps.showCodeOfConductModal) {
          this.setState({ buttonDisabled: true, remainingSeconds: 15 });

          const intervalId = setInterval(() => {
            const { remainingSeconds } = this.state;
            if (remainingSeconds > 0) {
              this.setState({ remainingSeconds: remainingSeconds - 1 });
            } else {
              clearInterval(intervalId);
              this.setState({ buttonDisabled: false, remainingSeconds: 15 });
            }
          }, 1000); // 1 second interval to update the remaining seconds
        }
      }
    );
  }
  async getUserDetails() {
    let userDetails = localStorage.getItem("token");
    if (userDetails) {
      const verifiedToken = await verifyToken(userDetails);
      return verifiedToken?.payload?.sub;
    }
    return null;
  }
  async componentDidMount() {
    const userDetails = await this.getUserDetails();
    this.setState(() => ({
      userDetails: { ...userDetails },
    }));
    this.getPopupContent();
  }

  getPopupContent = () => {
    Axios.get(config.API_BASE + "/popup/getPopupContent")
      .then((response) => {
        const res = response.data;
        const contents = res.data; // Assuming the data fetched is an array of contents

        // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const dayOfWeek = new Date().getDay();

        // Map the day of the week to the appropriate popupId
        const popupIdMap = {
          0: 1,
          1: 1, // Monday
          2: 2, // Tuesday
          3: 3, // Wednesday
          4: 4, // Thursday
          5: 5, // Friday
          6: 5,
        };
        // Get the popupId based on the current day of the week
        const selectedPopupId = popupIdMap[dayOfWeek];

        // Find the content based on the selectedPopupId from the fetched data
        const selectedContent = contents.find(
          (content) => content.popupId === selectedPopupId
        );

        // Update state with the selected content
        this.setState({ selectedContent });
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  storeUserInteraction(status) {
    const { selectedContent } = this.state;

    // Make an Axios POST request to store user's interaction
    Axios.post(config.API_BASE + "/popup/storeUserPopupInteraction", {
      userId: this.state.userDetails.id, // Use the userId from the component's state
      popupId: selectedContent.popupId, // Use popupId from the fetched popup content
      status,
    })
      .then((response) => {
        this.closeModal();
        this.props.changeCodeOfConductModal(false);
        this.props.changeStatusModal(true);
      })
      .catch((error) => {
        console.error("Error storing interaction:", error);
        // Handle error or display a notification to the user
      });
  }

  handleButton = (status) => {
    this.storeUserInteraction(status);
  };

  render() {
    const { selectedContent, buttonDisabled, remainingSeconds } = this.state;
    const contentArray = selectedContent?.description
      ? selectedContent.description.split("\n").filter(Boolean)
      : [];

    return (
      <div>
        <Modal
          size="lg"
          show={this.state.modalIsOpen}
          onHide={this.closeModal}
          className="markStatusModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.selectedContent.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.selectedContent !== "" && (
              <ol className="numbered-list">
                {contentArray.map((line, index) => (
                  <React.Fragment key={index}>
                    <li>{line.trim()}</li>
                    <br />
                  </React.Fragment>
                ))}
              </ol>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.handleButton(false)}
              disabled={buttonDisabled}
            >
              Decline
            </Button>
            <Button
              variant="primary"
              onClick={() => this.handleButton(true)}
              disabled={buttonDisabled}
            >
              Accept {buttonDisabled && `(${remainingSeconds} sec)`}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

class UpdatePreviousWeekModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFetched: false,
      actionItems: props.actionItems || [], // To store the previous week's action items
      accomplishment: "", // To store the optional key accomplishment
    };
  }

  // Fetch the previous week's action items when userId changes
  componentDidUpdate(prevProps) {
    // Check if userId has changed from null/undefined to a valid userId
    if (
      this.props.actionItems !== prevProps.actionItems &&
      !this.state.hasFetched
    ) {
      this.setState({ actionItems: this.props.actionItems, hasFetched: true });
    }
  }

  // Handle status updates for action items
  handleStatusChange = (actionId, value) => {
    this.setState((prevState) => {
      const updatedActionItems = prevState.actionItems.map((item) => {
        if (item.id === actionId) {
          return {
            ...item,
            status: value,
          };
        }
        return item;
      });
      return { actionItems: updatedActionItems };
    });
  };

  // Handle changes in the "Any other key accomplishment" field
  handleAccomplishmentChange = (e) => {
    this.setState({ accomplishment: e.target.value });
  };

  // Submit the updated statuses to the server
  handleSubmit = async () => {
    const { userId } = this.props;
    const { actionItems, accomplishment } = this.state;
    const { startDate, endDate } = getPreviousWeekDates();

    // Validate that all action item status fields are filled
    const allFilled = actionItems.every((item) => item.status?.trim() !== "");

    if (!allFilled) {
      toast.error(
        "Please fill in all the status updates for the action items."
      );
      return; // Stop submission if any field is empty
    }

    try {
      const response = await fetch(
        `${config.API_BASE}/user/updateUserActionsBulk`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            statusUpdates: actionItems.map((item) => ({
              id: item.id,
              status: item.status,
            })),
            startDate: startDate,
            endDate: endDate,
            accomplishment, // Send the optional accomplishment separately
          }),
        }
      );

      const data = await response.json();
      if (data.status) {
        toast.success("Status and accomplishment updated successfully");
        this.props.handleClose(); // Close the modal after submission
      } else {
        toast.error("Error updating status and accomplishment");
      }
    } catch (error) {
      console.log("API Error:", error);
    }
  };

  render() {
    const { show, handleClose } = this.props;
    const { actionItems, hasFetched, accomplishment } = this.state;

    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className="markStatusModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Previous Week's Key Accomplishment:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {actionItems.length > 0 ? (
            <Form>
              {actionItems.map((item, index) => (
                <Form.Group key={item.id}>
                  <Form.Label>
                    {index + 1}. {item.actionItem}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Update status"
                    value={item.status}
                    onChange={(e) =>
                      this.handleStatusChange(item.id, e.target.value)
                    }
                    required
                  />
                </Form.Group>
              ))}

              {/* Open-ended accomplishment question */}
              <Form.Group>
                <Form.Label>Any other key accomplishment (optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Mention any other key accomplishment"
                  value={accomplishment}
                  onChange={this.handleAccomplishmentChange}
                />
              </Form.Group>
            </Form>
          ) : hasFetched ? (
            <p>No actions found for the previous week.</p>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class CurrentWeekTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionItems: props.actionItems || [],
      newActionItem: "", // State to hold new action item input
      editingItemId: null, // Store the ID of the item being edited
      editedActionItem: "", // State to hold edited action item input
    };
  }

  handleInputChange = (e) => {
    this.setState({ newActionItem: e.target.value });
  };

  handleAddActionItem = () => {
    if (this.state.newActionItem.trim() && this.state.actionItems.length < 10) {
      const newItem = {
        id: Date.now(), // Temporary ID
        actionItem: this.state.newActionItem,
      };
      this.setState((prevState) => ({
        actionItems: [...prevState.actionItems, newItem],
        newActionItem: "", // Clear input after adding
      }));
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission behavior
      if (this.state.editingItemId) {
        // If editing, save the edited item
        this.handleSaveEditedActionItem();
      } else {
        // Otherwise, add a new item
        this.handleAddActionItem();
      }
    }
  };

  handleDeleteActionItem = (id) => {
    this.setState((prevState) => ({
      actionItems: prevState.actionItems.filter((item) => item.id !== id),
    }));
  };

  handleEditActionItem = (id) => {
    const itemToEdit = this.state.actionItems.find((item) => item.id === id);
    this.setState({
      editingItemId: id, // Set the editing item ID
      editedActionItem: itemToEdit.actionItem, // Set the action item being edited
    });
  };

  handleEditInputChange = (e) => {
    this.setState({ editedActionItem: e.target.value });
  };

  handleSaveEditedActionItem = () => {
    const { actionItems, editingItemId, editedActionItem } = this.state;
    const updatedActionItems = actionItems.map((item) =>
      item.id === editingItemId
        ? { ...item, actionItem: editedActionItem }
        : item
    );
    this.setState({
      actionItems: updatedActionItems,
      editingItemId: null, // Reset after saving
      editedActionItem: "",
    });
  };

  handleSubmit = async () => {
    const { userId } = this.props;
    const { actionItems } = this.state;
    const { startDate, endDate } = getCurrentWeekDates();

    try {
      const response = await fetch(
        `${config.API_BASE}/user/insertUserActionsBulk`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            actionItems: actionItems.map((item) => ({
              actionItem: item.actionItem.trim(),
            })),
            startDate,
            endDate,
          }),
        }
      );

      const data = await response.json();
      if (data.status) {
        toast.success("Current week tasks added successfully");
        this.props.handleClose(); // Close the modal after submission
      } else {
        const errorMsg = data?.message;
        toast.error(`Error adding current week tasks ${errorMsg}`);
      }
    } catch (error) {
      console.log("API Error:", error);
    }
  };

  render() {
    const { show, handleClose } = this.props;
    const { actionItems, newActionItem, editingItemId, editedActionItem } =
      this.state;

    const isCloseDisabled =
      newActionItem.trim() === "" || actionItems.length >= 10;
    const isSubmitDisabled =
      actionItems.length === 0 || actionItems.length > 10;

    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className="markStatusModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            What are your key (top 3-10) priorities this week?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {actionItems.map((item, index) => (
              <Form.Group
                key={item.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {editingItemId === item.id ? (
                  // If editing, show input field for the selected item
                  <Form.Control
                    type="text"
                    value={editedActionItem}
                    onChange={this.handleEditInputChange}
                    onKeyPress={this.handleKeyPress} // Handle Enter key press for edit
                    style={{ flex: 1 }}
                  />
                ) : (
                  // Otherwise, display the action item as text
                  <Form.Label style={{ flex: 1 }}>
                    {index + 1}. {item.actionItem}
                  </Form.Label>
                )}
                <div>
                  {editingItemId === item.id ? (
                    <FaSave
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      onClick={this.handleSaveEditedActionItem}
                      title="Save"
                    />
                  ) : (
                    <>
                      <FaEdit
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        onClick={() => this.handleEditActionItem(item.id)}
                        title="Edit"
                      />
                      <FaTrash
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        onClick={() => this.handleDeleteActionItem(item.id)}
                        title="Delete"
                      />
                    </>
                  )}
                </div>
              </Form.Group>
            ))}
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Add new action item"
                value={newActionItem}
                onChange={this.handleInputChange}
                onKeyPress={this.handleKeyPress} // Handle Enter key press for new item
              />
            </Form.Group>
            {actionItems.length > 0 ? (
              <p>A maximum of 10 priorities can be added.</p>
            ) : (
              ""
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isCloseDisabled}
          >
            Close
          </Button>
          <Button variant="primary" onClick={this.handleAddActionItem}>
            Add Item
          </Button>
          <Button
            variant="success"
            onClick={this.handleSubmit}
            disabled={isSubmitDisabled}
          >
            Submit All
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Dashboard;
